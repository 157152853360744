<!-- @format -->
<template>
  <div class="base-kinostart" :class="$mq">
    <!-- eslint-disable-next-line -->
    <div class="text" :class="$mq" v-html="kinostart"></div>
  </div>
</template>
<script>
  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
    },
  }
</script>
<style lang="scss">
  .base-kinostart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #d5c9b6;
    font-weight: 400;
    font-family: 'Lora';
    margin: 10px 0;

    &.md {
      text-align: center;
      width: 95%;
      font-family: 'Lora';
      color: #d5c9b6;
      font-weight: 400;
      margin: 0 auto;
      font-size: 24px !important;

      .text {
        margin-top: -28px;
      }
    }

    &.sm {
      font-size: 18px !important;
      text-align: center;
      font-family: 'Lora';
      color: #d5c9b6;
      font-weight: 400;
      margin: 0px auto;

      .text {
        margin-top: -26px;
      }
    }

    /*  &.sm {
    font-size: 36px;
    padding: 10px 0;
    margin: 0 auto;
    background-color: white;
    text-align: center;
  }
  &.md {
    font-size: 55px;
    padding: 10px 0;
    margin: 0;
    background-color: white;
    text-align: center;
  } */
  }
</style>
