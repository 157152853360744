<template>
  <div class="inhalt-overview" :class="$mq" id="inhalt">
    <div class="inhalt desktop-inhalt" :class="$mq" id="desktop-inhalt">
      <div class="desktop-inhalt-content">
        <div class="inhalt-movie">
          <img src="@/assets/images/inhalt/von.png" alt="" :class="$mq" />
        </div>
        <div class="inhalt-bg-text">
          <div class="inhalt-inspriert">
            <img src="@/assets/images/inhalt/inspriert.png" alt="" :class="$mq" />
          </div>
        </div>
        <div class="inhalt-text">
          <div class="inhalt-text-content" :class="$mq">
            <div class="text-left" :class="$mq">
              <p>
                <b>
                  Inspiriert von der wahren Geschichte um einen blutigen
                  Nachbarschaftsstreit in einem idyllischen, kleinen Bergdorf.
                  International gefeiert und mehrfach ausgezeichnet!</b><br />
              </p>
              <p class="text-bottom">
                Antoine und Olga, ein Ehepaar aus Frankreich, haben sich in
                einem abgeschiedenen, spanischen Bergdorf niedergelassen. Dort
                führen sie ein ruhiges Leben, obwohl ihr Zusammenleben mit den
                Einheimischen nicht so harmonisch ist, wie sie es sich wünschen.
                Ein Konflikt mit ihren Nachbarn, den Anta-Brüdern, führt dazu,
                dass die Spannungen im Dorf einen Punkt erreichen, an dem es
                kein Zurück mehr gibt.
              </p>
            </div>
          </div>
        </div>
        <div class="inhalt-zitate" :class="$mq">
          <div class="inhalt-zitat-1">
            <div class="zitat-sterne">
              <img src="@/assets/images/inhalt/sterne.png" alt="" :class="$mq" />
            </div>
            <div class="zitat-text">
              „Atemberaubend<br />
              spannend“
            </div>
            <div class="zitat-author">THE GUARDIAN</div>
          </div>
          <div class="inhalt-zitat-1">
            <div class="zitat-sterne">
              <img src="@/assets/images/inhalt/sterne.png" alt="" :class="$mq" />
            </div>
            <div class="zitat-text">
              „Brilliant geschrieben und<br />
              fantastisch gespielt“
            </div>
            <div class="zitat-author">FINANCIAL TIMES</div>
          </div>
          <div class="inhalt-zitat-1">
            <div class="zitat-sterne">
              <img src="@/assets/images/inhalt/sterne.png" alt="" :class="$mq" />
            </div>
            <div class="zitat-text">
              „Einer der besten<br />
              Psychothriller des Jahres“
            </div>
            <div class="zitat-author">YORCKER</div>
          </div>
        </div>
        <div class="dvd" id="dvd" :class="$mq">
          <div class="wrapper container">
            <div class="left_side">
              <img class="packshot" src="@/assets/images/dvd/packshot.png" alt="" />
            </div>
            <div class="right_side">
              <div class="txt">
                <p>
                  <span class="extra">DVD</span>
                </p>
                <div class="amz-btn">
                  <a href="https://amzn.to/3UE8srF" class="vod_btn" target="_blank">
                    <img src="@/assets/images/dvd/amazon.png" alt="" />
                  </a>
                  <!--<span>*</span>-->
                </div>
                <p>
                  <span class="extra">BLU-RAY</span>
                </p>
                <div class="amz-btn">
                  <a href="https://www.amazon.de/wilde-Tiere-Blu-ray-Luis-Zahera/dp/B0CPP5H2JD/ref=tmm_blu_swatch_0?_encoding=UTF8&qid=1707510716&sr=8-2"
                    class="vod_btn" target="_blank">
                    <img src="@/assets/images/dvd/amazon.png" alt="" />
                  </a>
                  <!--<span>*</span>-->
                </div>
              </div>
              <div class="video">
                <h2>Video on Demand</h2>
                <ul class="vod_btns">
                  <li>
                    <a href="https://tv.apple.com/de/movie/wie-wilde-tiere/umc.cmc.vgmres8wrhitt0fbn090uvhc"
                      target="_blank"><img src="@/assets/images/dvd/itunes.jpg" /></a>
                  </li>
                  <li>
                    <a href="https://amzn.to/48s1gC6" target="_blank"><img src="@/assets/images/dvd/prime.jpg" /></a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/watch?v=ICkRxK7MGLo" target="_blank"><img
                        src="@/assets/images/dvd/google.jpg" /></a>
                  </li>
                  <li>
                    <a href="https://www.videoload.de/film/wie-wilde-tiere/GN_MV020744600000" target="_blank"><img
                        src="@/assets/images/dvd/videoload.jpg" /></a>
                  </li>
                  <li>
                    <a href="https://ondemand.yorck.de/film/wie-wilde-tiere-as-bestas/" target="_blank"><img
                        src="@/assets/images/dvd/yorck.jpg" /></a>
                  </li>
                  <li>
                    <a href="https://store.sky.de/product/wie-wilde-tiere/c1f2fcb2-152a-4c8f-86db-9ea9529aaae1"
                      target="_blank"><img src="@/assets/images/dvd/sky.jpg" /></a>
                  </li>
                  <li>
                    <a href="https://www.kino-on-demand.com/movies/wie-wilde-tiere-dk9nl" target="_blank"><img
                        src="@/assets/images/dvd/ondemand.png" /></a>
                  </li>
                  <li>
                    <a href="https://www.rakuten.tv/de/search?q=wie%20wilde%20tiere&content_type=movies&content_id=wie-wilde-tiere"
                      target="_blank"><img src="@/assets/images/dvd/rakuten.jpg" /></a>
                  </li>
                </ul>
                <p class="note">
                  * Die mit Sternchen (**) gekennzeichneten Links sind
                  sogenannte Affiliate-Links. Wenn Sie auf einen Affiliate-Link
                  klicken und über<br />
                  diesen Link einkaufen, erhält Prokino vom betreffenden
                  Online-Shop oder Anbieter eine Provision. Für Sie verändert
                  sich der Preis nicht.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
export default {
  props: {
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
    ratio: {
      type: Number,
      default: 1.78,
    },
    trailer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFixed() {
      return this.position === 'fixed'
    },
  },

  methods: {
    ytVideo() {
      EventBus.$emit('open-yt')
    },
    kinoliste() {
      EventBus.$emit('open-kinoliste')
    },
  },
}
</script>

<style lang="scss">
.inhalt-overview {
  display: flex;
  flex-direction: column;
  background-image: url('~@/assets/images/inhalt/bg.png');
  background-size: cover;
  background-position: center;
  height: 1962px;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  &.xxl,
  &.xxxl,
  &.xxxxl {
    background-image: url('~@/assets/images/inhalt/bg_large.png');
    height: 1962px;
  }

  .inhalt {
    z-index: 99;
    $self: &;
    width: 100%;
    font-family: 'Lora';
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .desktop-inhalt-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 1308px;

      .inhalt-movie {
        margin-bottom: 45px;
      }

      .inhalt-bg-text {
        background-image: url('~@/assets/images/inhalt/linien_2.png');
        height: 1px;
        height: 1px;
        width: 2555px;
        background-size: cover;
        background-position: center;

        .inhalt-inspriert {
          text-align: center;
          margin-top: -15px;
        }
      }

      .inhalt-text {
        margin-top: 60px;

        .inhalt-text-content {
          display: flex;
          margin: 0 auto;

          .text-left {
            margin-top: 10px;

            .text-bottom {
              width: 75%;
              margin-top: 15px;
            }

            p {
              width: 80%;
              margin: 0 auto;
            }
          }

          span {
            font-weight: bold;
          }

          .text-left p {
            line-height: 1.5;
            font-size: 17px;
            font-weight: 500;
            text-align: center;
          }
        }
      }

      .inhalt-zitate {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        margin-left: 65px;

        .inhalt-zitat-1 {
          text-align: center;
          margin-right: 60px;

          .zitat-sterne {
            width: 60%;
            margin: 0 auto;
          }

          .zitat-text {
            margin: 10px 0;
            font-size: 18px;
          }

          .zitat-author {
            font-size: 12px;
          }
        }

        .zitat-content {
          text-align: center;

          .zitat-content-text {
            font-family: 'Roboto Slab';
            font-size: 25px;
            color: white;
          }

          .zitat-content-author {
            margin-bottom: 15px;
            margin-top: 5px;
            font-size: 15px;
            font-family: 'Raleway';
            font-weight: 500;
          }
        }
      }

      .dvd {
        z-index: 99;
        $self: &;
        width: 1024px;
        height: 388px;
        font-family: 'Urbanist';
        padding: 0;
        padding-bottom: 20px;
        margin: 40px auto 20px auto;

        .container {
          padding: 0;
        }

        .wrapper {
          display: flex;
          justify-content: center;
          width: 100%;

          .left_side {
            margin-top: 5px;
            margin-right: -30px;
          }

          .right_side {
            margin-top: 25px;

            .txt {
              margin-top: 10px;
              flex: 1;
              margin-left: 66px;
              display: flex;
              flex-direction: row;
              align-items: center;

              .extra {
                font-weight: 500;
                font-family: 'Lora';
                font-size: 30px;
                color: black;
              }

              p {
                margin-bottom: 0;
              }

              ul {
                margin-top: 10px;
                padding-left: 0 !important;
              }

              ul,
              li {
                //font-size: 6px;
                margin-left: 10px;
                margin-bottom: 0;
              }

              li span {
                font-size: 18px;
              }

              .amz-btn {
                display: flex;
                height: 46px;
                margin-left: 10px;
                margin-top: 10px;
                margin-right: 15px;

                .disabled {
                  pointer-events: none;
                  opacity: 0.5;
                }

                span {
                  display: block;
                  margin-left: 3px;
                  font-weight: 700;
                  font-family: 'Oswald';
                }
              }

              .vod_btn {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 42px;
                margin-top: 5px;
                margin-bottom: 77px;

                .disabled {
                  pointer-events: none;
                  opacity: 0.5;
                }

                img {
                  vertical-align: middle;
                  max-height: 100%;
                }
              }
            }

            h2 {
              font-size: 30px;
              text-transform: uppercase;
              color: black;
              margin-left: 66px;
              font-weight: 500;
              font-family: 'Lora';
              margin-bottom: 0 !important;
              margin-top: 30px !important;
            }

            .vod_btns {
              margin-left: 60px;
              position: relative;
              width: 569px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-content: flex-start;
              /* vertical */
              justify-content: flex-start;
              /* horizontal */
              align-items: stretch;
              padding: 0 !important;
              margin-top: 0 !important;

              /* same height */
              .disabled {
                pointer-events: none;
                opacity: 0.5;
              }

              li {
                list-style: none;
                margin: 7px;
              }

              .vod_btn {
                flex: 0 1 50%;
                margin: 0 20px 14px 0;
                padding: 0 !important;

                .disabled {
                  pointer-events: none;
                  opacity: 0.5;
                }
              }
            }

            .note {
              font-size: 10px;
              line-height: 0.8rem;
              font-weight: 500;
              text-align: left;
              font-family: 'Roboto';
              margin-top: -10px;
              margin-left: 65px;
            }
          }
        }
      }
    }
  }

  .rote-linie {
    position: absolute;
    bottom: -6px;
    width: 100%;

    img {
      width: 100%;
    }

    .rote_small {

      &.lg,
      &.xl,
      &.xxl {
        display: block;
      }

      &.xxxl,
      &.xxxxl {
        display: none;
      }
    }

    .rote_long {

      &.lg,
      &.xl,
      &.xxl {
        display: none;
      }

      &.xxxl,
      &.xxxxl {
        display: block;
      }
    }
  }
}
</style>
