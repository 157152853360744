<!-- @format -->

<template>
  <div
    class="scroll-container"
    :class="{ 'overflow-hidden': !animationComplete }"
  >
    <!--  <DesktopHeader />
          v-if="showLoadingSection"-->
    <transition name="fade">
      <div
        v-if="showLoadingSection"
        class="loading-section"
        :class="$mq"
        ref="loadingSection"
      >
        <div class="loading-section-top" :class="$mq">
          <div class="loading-menschen" :class="$mq">
            <img
              class="loading-bild-1"
              src="@/assets/images/top/new/menschen.png"
              alt=""
              :class="$mq"
            />
            <img
              class="loading-bild-2"
              src="@/assets/images/top/new/menschen_small.png"
              alt=""
              :class="$mq"
            />
          </div>
          <div class="loading-hashtag" :class="$mq">
            <p :class="$mq">PROKINO PRÄSENTIERT</p>
          </div>
          <div class="loading-title" :class="$mq">
            <img src="@/assets/images/top/new/loading_title.png" alt="" />
          </div>
        </div>
        <div class="loading-kasten" :class="$mq">
          <div class="loading-pfeil" :class="$mq">
            <img src="@/assets/images/top/new/pfeil.png" :class="$mq" alt="" />
          </div>
          <div class="weisser-kasten" :class="$mq">
            <img
              src="@/assets/images/top/new/weisser_kasten.png"
              alt=""
              :class="$mq"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="content-container">
      <DesktopTop
        :ratio="ratio"
        :trailer="trailer"
        :kinostart="kinostart"
        v-if="!showLoadingSection"
      />

      <!--
    <Crew />
    <Musik />-->
      <Inhalt />

      <!-- 
    <Fotos />-->
      <BaseFooter :billing="true" :kinostart="kinostart" />

      <BaseYoutube :youtube="$config.youtube" :margin="100" />
    </div>
  </div>
</template>

<script>
  import Inhalt from '@/views/Inhalt.vue'

  /* 
import Fotos from '@/views/Fotos.vue'
import Hintergrundinfos from '@/views/Hintergrund.vue'
import Partner from '@/views/Partner.vue'
import Regie from '@/views/Regie.vue'
import Timeline from '@/views/Timeline.vue'
import Statement from '@/views/Statement.vue'
import Clip from '@/views/Clip.vue'
 */
  import moment from 'moment'

  export default {
    data() {
      return {
        showLoadingSection: true,
        fadeIn: false,
        animationComplete: false,
      }
    },
    components: {
      Inhalt,
      /*  
      Fotos,
  Hintergrundinfos,
  Partner,
    Regie, 
    Fotos,
    Timeline,
    Statement,
    Clip,
*/
    },
    created() {
      window.addEventListener('scroll', this.handleScroll)
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
      trailer() {
        return this.$config.youtube.id !== ''
      },
    },
    mounted() {
      this.$nextTick(() => {
        const loadingSection = this.$refs.loadingSection
        loadingSection.addEventListener('animationend', () => {
          loadingSection.classList.add('animate-complete')
          setTimeout(() => {
            this.showLoadingSection = false
            setTimeout(() => {
              this.animationComplete = true
            }, 200)
          }, 2000) // 6000 milliseconds = 6 seconds
        })
      })
    },
    methods: {
      handleScroll() {
        const element = document.querySelector('.maedchen_fadein')
        if (element) {
          const rect = element.getBoundingClientRect()
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight
          this.fadeIn = rect.top <= windowHeight
        }
      },
      logout() {
        this.$store.dispatch('Auth/userSignOut')
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .scroll-container {
    z-index: 0;
    position: relative;
    width: 100%;

    &.overflow-hidden {
      overflow-y: hidden;
      overflow-x: hidden;
    }

    .loading-section {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99999999;
      background-image: url('~@/assets/images/top/new/loading_image.jpg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100vh;
      /* Vollständige Bildschirmhöhe verwenden */
      display: flex;
      justify-content: center;
      align-items: center;

      &.lg {
        height: 100%;
      }

      &.xxxl,
      &.xxxxl {
        height: 100vh;
      }

      .loading-kasten {
        position: absolute;
        bottom: 0;
        margin: 0 auto;
        animation: opacityAnimation 2s ease;

        .loading-pfeil {
          position: absolute;
          top: 53%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          transition: transform 0.3s ease;
          animation: bounceAnimation 1s ease infinite;

          &.lg {
            width: 40%;
            margin: 0 auto;

            img {
              width: 100%;
            }
          }
        }

        @keyframes bounceAnimation {
          0%,
          100% {
            transform: translate(-50%, -50%);
          }

          50% {
            transform: translate(-50%, -30%);
          }
        }

        .weisser-kasten {
          position: relative;

          /* Relative Positionierung, um den absoluten Pfeil innerhalb des Kastens zu positionieren */
          &.lg {
            width: 80%;
            margin: 0 auto;

            img {
              width: 100%;
            }
          }
        }
      }

      .loading-section-top {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: 450px;

        &.lg {
          margin-top: 400px;
        }

        &.xxxl {
          margin-top: 500px;
        }

        &.xxxxl {
          margin-top: 750px;
        }

        .loading-hashtag {
          p {
            font-family: 'Lora';
            font-size: 18px;
            text-align: center;
            color: #d5c9b6;
            margin: 0;
            width: 100%;
          }

          &.xl,
          &.xxl {
            p {
              font-size: 16px;
            }

            width: 100%;
          }

          &.lg {
            p {
              font-size: 14px;
            }
          }
        }

        .loading-title {
          animation: opacityAnimation 2s ease;
          width: 84%;
          margin-top: 30px;
          z-index: 9999;

          img {
            width: 100%;
          }

          &.xxxl,
          &.xxxxl {
            width: 100%;
          }
        }

        .loading-menschen {
          position: absolute;
          bottom: 0;
          text-align: center;
          margin-left: -100px; // Just for positioning, adjust as needed
          animation: scaleAnimation 1.5s ease-in-out; // Set the initial width to 50%

          img {
            width: 100%;
            min-width: 50%; // Ensure the image never becomes smaller than 50%
          }

          &.lg {
            width: 60%;
            text-align: center;
          }

          .loading-bild-2 {
            &.xxxxl {
              display: none;
            }
          }

          .loading-bild-1 {
            &.lg,
            &.xl,
            &.xxl,
            &.xxxl {
              display: none;
            }
          }
        }

        .loading-hashtag {
          animation: opacityAnimation2 2s ease;
          width: 28%;
          z-index: 9999;

          img {
            width: 100%;
          }

          &.xxxl,
          &.xxxxl {
            width: 36%;
          }
        }
      }
    }

    @keyframes opacityAnimation {
      0% {
        opacity: 0;
      }

      20% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes opacityAnimation2 {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes scaleAnimation {
      0% {
        transform: scale(1.1);
        /* Größer */
      }

      100% {
        transform: scale(1);
        /* Normalgröße */
      }
    }

    /**  .loading-section.animate-complete .loading-title {
    opacity: 1;
  }
  .loading-section.animate-complete .loading-kasten {
    opacity: 1;
  } */
  }

  @media (max-height: 941px) {
    .scroll-container {
      .loading-section {
        .loading-section-top {
          .loading-hashtag {
            width: 22%;
          }

          .loading-title {
            width: 47%;
            margin-bottom: 50px;
          }
        }
      }
    }
  }

  .content-container {
    height: 100vh;
  }
</style>
