<!-- @format -->

<template>
  <footer class="footer" :class="$mq">
    <div class="mob-footer-bg" :class="$mq">
      <img src="@/assets/images/footer/bg_mob_1.png" alt="" />
    </div>
    <div class="footer__content" :class="$mq">
      <div class="footer__tt" :class="$mq">
        <img
          :src="require(`@/assets/images/` + $config.tt_small)"
          alt=""
          :class="$mq"
        />
      </div>
      <component
        class="footer__company"
        :is="companyFooter"
        :teilnahmebedingung="teilnahmebedingung"
      ></component>
      <component class="footer__social" :is="companySocial"></component>
      <div class="footer-media" :class="$mq">
        <img src="@/assets/images/footer/media.png" alt="" />
      </div>
      <!---<div class="cb-restore"></div>-->
    </div>
  </footer>
</template>

<script>
  export default {
    props: {
      billing: {
        type: Boolean,
        default: true,
      },
      youtubenote: {
        type: Boolean,
        default: true,
      },
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      teilnahmebedingung: {
        type: String,
        default: 'leer',
      },
    },
    computed: {
      companyFooter() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Footer.vue`
          )
      },
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
  }
</script>

<style lang="scss">
  .footer {
    $self: &;
    z-index: 5;
    position: relative;
    text-align: center;
    height: 806px;
    font-size: 0.875rem;
    width: 100%;
    background-image: url('~@/assets/images/footer/bg.jpg');
    background-size: cover;
    background-position: center;

    .mob-footer-bg {
      padding-top: 10px;

      &.md,
      &.sm {
        img {
          width: 100%;
        }
      }

      &.lg,
      &.xl,
      &.xxl,
      &.xxxl,
      &.xxxxl {
        display: none;
      }
    }

    &.md,
    &.sm {
      background-image: none;
      height: auto;
    }

    &.xxl,
    &.xxxl,
    &.xxxxl {
      background-image: url('~@/assets/images/footer/bg_large.jpg');
    }

    .footer__content {
      position: relative;
      margin: 0 auto;

      .footer__tt {
        margin-bottom: 15px;

        img {
          margin-top: 45px;
        }

        &.sm,
        &.md {
          padding: 0;
          width: 80%;
          margin: 0 auto;

          img {
            margin-top: -35px;
            width: 100%;
          }
        }
      }

      &.sm,
      &.md {
        width: 100%;
        position: absolute;
        top: 30%;
      }
    }

    .footer-media {
      img {
        width: 100px;
        /* Hier die gewünschte Breite in Pixeln eintragen */
        height: auto;
        /* Damit das Seitenverhältnis beibehalten wird */
        margin-bottom: 25px !important;
      }

      &.sm,
      &.md {
        margin-bottom: 0px;

        img {
          width: 100px;
          height: auto;
        }
      }
    }

    #{$self}__billing {
      margin-top: 15px;

      &.sm,
      &.md {
        margin-top: 10px;
      }

      &.sm,
      &.md,
      &.lg,
      &.xl {
        padding: 0 5%;
      }

      &.xxl,
      &.xxxl,
      &.xxxxl {
        img {
          max-width: 1000px;
        }
      }
    }
  }
</style>
