<template>
  <mq-layout mq="lg+">
    <div class="index" id="index" :class="$mq">
      <div class="index-container" :class="$mq">
        <div class="top-container">
          <div class="top-container-content">
            <div class="middle-content-top">
              <!--              <div class="stoerer">
                <a href="/Kinoliste_NONNENRENNEN.pdf" download>
                  <img
                    class="logo-top"
                    src="@/assets/images/top/kinouebersicht.png"
                    alt=""
                  />
                </a>
              </div>-->
              <div class="middle-content-wrapper">
                <div class="middle-content-top-top">
                  <img
                    class="praesi-top"
                    :class="$mq"
                    src="@/assets/images/top/new/praesi.png"
                    alt=""
                  />

                  <BaseKinostart
                    :kinostart="kinostart"
                    class="desktop-kinostart"
                  />
                  <div
                    class="mehr-top"
                    v-scroll-to="{
                      el: '#' + 'dvd',
                      offset: -40,
                    }"
                  >
                    <img src="@/assets/images/top/new/mehr.png" alt="" />
                  </div>
                </div>
                <div class="middle-content-top-bottom">
                  <div class="top-trailer" :class="$mq">
                    <div
                      class="yt_block"
                      id="yt_block"
                      v-if="$config.youtube.display"
                    >
                      <a href="#" @click.prevent="emitPressClick">
                        <div style="position: relative">
                          <div class="trailer-misc" :class="$mq">
                            <img
                              class="play-btn"
                              :class="$mq"
                              src="@/assets/images/trailer/play_02.png"
                              alt
                            />
                            <p class="trailer-view" :class="$mq">
                              TRAILER ANSEHEN
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <YoutubeModal />
                  </div>
                  <div class="teilen-desktop" :class="$mq">
                    <div class="teilen-desktop-content" :class="$mq">
                      <div class="sharing" :class="$mq">
                        <div class="bg-teilen">
                          <span>Teilen auf</span>
                        </div>
                        <div class="icons">
                          <a
                            target="_blank"
                            :href="`https://www.facebook.com/sharer/sharer.php?u=${$config.url}`"
                            class="share__item share__fb"
                            ><img src="@/assets/images/top/fb.png" alt="" />
                          </a>

                          <a
                            :href="$config.instagram.link"
                            target="_blank"
                            class="share__item share__instagram"
                          >
                            <img src="@/assets/images/top/ig.png" alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="top-tt" :class="$mq">
                    <img :src="require(`@/assets/images/` + $config.tt)" alt />
                  </div>
                </div>
              </div>
            </div>
            <div class="gradient-top">
              <img
                :class="$mq"
                class="wolken-top-small"
                src="@/assets/images/top/new/wolken_top_small.png"
                alt=""
              />
              <img
                :class="$mq"
                class="wolken-top"
                src="@/assets/images/top/new/wolken_top.png"
                alt=""
              />
            </div>
            <div class="gradient-bottom">
              <img
                :class="$mq"
                class="wolken-bottom-small"
                src="@/assets/images/top/new/wolke_small.png"
                alt=""
              />
              <img
                :class="$mq"
                class="wolken-bottom"
                src="@/assets/images/top/new/wolken.png"
                alt=""
              />
            </div>
            <video
              src="@/assets/videos/top_video.mp4"
              loop
              muted
              autoplay
              playsinline
            ></video>
          </div>
        </div>
      </div>
    </div>
  </mq-layout>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'
  import PressModal from '@/components/Base/Elements/Modals/YoutubeModal.vue'
  import BaseCitesFade from '@/components/Base/Cites/BaseCitesFade.vue'
  import YoutubeModal from '../Elements/Modals/YoutubeModal.vue'

  export default {
    components: { YoutubeModal },
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      components: {
        PressModal,
        BaseCitesFade,
      },
      ratio: {
        type: Number,
        default: 1.78,
      },
      trailer: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showPlayButton: true,
      }
    },
    computed: {
      isFixed() {
        return this.position === 'fixed'
      },
    },
    methods: {
      emitPressClick() {
        EventBus.$emit('ytmodal')
        this.showPlayButton = false
      },
      ytVideo() {
        EventBus.$emit('open-yt')
      },
      kinoliste() {
        EventBus.$emit('open-kinoliste')
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  .isFixed {
    position: fixed;
  }

  .isAbsolute {
    position: absolute;
  }

  .index {
    z-index: 0;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0 !important;

    .desktop-kinostart {
      background-color: none;
      background-image: url('~@/assets/images/top/new/linien.png');
      height: 1px;
      width: 2555px;
      background-size: cover;
      background-position: center;
      margin: 0;
      margin-top: 15px;

      &.lg,
      &.xl,
      &.xxl,
      &.xxxl,
      &.xxxxl {
        font-size: 45px;
      }

      &.sm,
      &.md {
        font-weight: 400;
        $min_width: 300px;
        $max_width: 1023px;
        $min_font: 22px;
        $max_font: 55px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
    }

    .index-container {
      height: 100vh;
      /* Set the height to 100% of the viewport height */
      width: 100%;
      background-color: white;

      .top-container {
        .top-container-content {
          height: 100vh;
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          .gradient-top {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            .wolken-top {
              height: 313px;

              &.lg,
              &.xl,
              &.xxl {
                display: none;
              }
            }

            .wolken-top-small {
              height: 313px;
              width: 100%;

              &.xxxl,
              &.xxxxl {
                display: none;
              }
            }
          }

          video {
            object-fit: cover;
            height: 100vh;
            width: 100%;
          }

          .gradient-bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-image: url('~@/assets/images/top/new/wolken.png');
            background-size: cover;
            background-position: center;
            height: 422px;

            .wolken-bottom {
              display: none;

              &.lg,
              &.xl,
              &.xxl {
                display: none;
              }
            }

            .wolken-bottom-small {
              display: none;
              height: 441px;

              &.xxxl,
              &.xxxxl {
                display: none;
              }
            }
          }

          .fade-enter-active,
          .fade-leave-active {
            transition: opacity 1s;
          }

          .fade-enter,
          .fade-leave-to {
            opacity: 0;
          }

          .middle-content-top {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 99998;
            width: 100%;

            .stoerer {
              position: absolute;
              top: 20px;
              right: 20px;
              width: 7%;

              img {
                width: 100%;
              }
            }

            .middle-content-wrapper {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              align-items: center;
              width: 100%;
              height: 100vh;

              .middle-content-top-top {
                margin-top: 25px;
                text-align: center;

                .praesi-top {
                  margin-bottom: 40px;
                }

                .logo-top {
                  margin-left: -800px;
                  margin-top: 25px;

                  &.lg {
                    margin-left: -700px;
                  }
                }

                .mehr-top {
                  cursor: pointer;
                  margin-right: -850px;
                  margin-top: 50px;
                }
              }

              .middle-content-top-bottom {
                position: relative;

                .top-trailer {
                  z-index: 9999;
                  pointer-events: auto;
                  cursor: pointer;
                  text-align: center;
                  position: absolute;
                  width: 100%;

                  .trailer-misc {
                    position: absolute;
                    z-index: 111;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    &.xxxl,
                    &.xxxxl {
                      .play-btn {
                        width: 15%;
                      }
                    }

                    .play-btn {
                      width: 12%;
                    }
                  }

                  .trailer-view {
                    font-family: 'Lora';
                    color: white;
                    font-size: 20px;
                    font-weight: 400;

                    &.xxxl,
                    &.xxxxl {
                      font-size: 20px;
                    }
                  }
                }

                .teilen-desktop {
                  width: 100%;
                  position: absolute;
                  margin-top: 153px;
                  margin-left: 520px;

                  &.lg {
                    margin-left: 600px;
                  }

                  .teilen-desktop-content {
                    .sharing {
                      display: flex;
                      flex-direction: row;

                      &.lg {
                        span {
                          font-size: 15px;
                        }
                      }

                      .bg-teilen {
                        width: 164px;
                        height: 39px;
                        border-radius: 30px;
                        margin-top: 7px;
                        text-align: center;
                        margin-right: 36px;

                        span {
                          font-size: 17px;
                          font-weight: 400;
                          font-family: 'Lora';
                          text-align: center;
                          text-transform: uppercase;
                          line-height: 1.2;
                          color: white;
                          margin-right: -115px;
                          margin-top: -10px;
                        }
                      }

                      .share__fb,
                      .share__instagram {
                        margin-right: 5px;

                        img {
                          width: 27%;
                        }
                      }
                    }
                  }
                }

                .top-tt {
                  margin-top: 80px;
                  padding-top: 85px;

                  &.xxxl,
                  &.xxxxl {
                    margin-top: 40px;
                  }

                  &.lg {
                    margin-top: 40px;
                    width: 100%;
                    text-align: center;

                    img {
                      width: 85%;
                    }
                  }

                  &.xl,
                  &.xxl {
                    width: 100%;
                    text-align: center;

                    img {
                      width: 85%;
                    }
                  }

                  margin-bottom: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
