<!-- @format -->
<template>
  <div class="mobile-header">
    <div class="mobile-header__wrapper">
      <div class="mobile-header__trigger-wrapper" @click="openMenu">
        <a id="nTrigger" class="mobile-header__trigger">
          <i></i><i></i><i></i>
        </a>
        <!--       <div class="mobile-social">
          <a
            class="mr-1 yt_blue"
            href="https://www.youtube.com/user/PROKINO"
            target="_blank"
          >
            <img src="@/assets/images/prokino/yt_blue.png" alt="" />
          </a>
          <a
            class="mr-1"
            href="https://www.instagram.com/prokinofilm/?hl=de"
            target="_blank"
          >
            <img src="@/assets/images/prokino/ig_blue.png" alt="" />
          </a>
          <a
            class="mr-1"
            href="https://www.facebook.com/Prokino"
            target="_blank"
          >
            <img src="@/assets/images/prokino/fb_blue.png" alt="" />
          </a>
        </div>-->
      </div>
    </div>
    <div
      id="mobile_nav"
      class="offcanvas-bar"
      :class="{ 'show-offcanvas': open }"
    >
      <div
        class="offcanvas-bar__wrapper"
        :class="$mq"
        v-if="$router.currentRoute.path === '/mobile'"
      >
        <div
          v-for="(value, key) in this.$config.navigation"
          :key="value.sitename"
        >
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <div
            v-if="key !== 'Trailer' && key !== 'Kinopremiere'"
            class="nav-item"
            :class="$mq"
            v-scroll-to="{ el: '.mob-' + value.sitename, offset: -55 }"
            @click="closeMenu()"
          >
            {{ key }}
          </div>
          <div
            v-if="key === 'Kinopremiere'"
            class="nav-item"
            @click="openPremiere"
          >
            {{ key }}
          </div>
        </div>
        <div class="offcanvas-bar__kinofinder">
          <div
            :href="$config.kinoservice.finder + $config.kinoservice.slug"
            target="_blank"
            class="nav-item"
            :class="$mq"
            @click="open_de_kinofinder"
          >
            {{ $config.kinoservice.finder_text }}
          </div>
        </div>
        <div class="offcanvas-bar__filmwecker">
          <div target="_blank" class="nav-item" @click="open_de_filmwecker">
            {{ $config.kinoservice.alert_text }}
          </div>
        </div>
        <div class="offcanvas-bar__share nav-item">
          Teilen
          <div class="offcanvas-bar__share-btns" :class="$mq">
            <div class="offcanvas-bar__share-btns-bottom">
              <a
                target="_blank"
                :href="`https://www.facebook.com/sharer/sharer.php?u=${$config.url}`"
                class="offcanvas-bar__share-item offcanvas-bar__share-fb external"
                :class="$mq"
                ><img src="@/assets/images/top/fb.png" alt="" />
              </a>
              <a
                :href="`WhatsApp://send?text=${$config.whatsapp.text}`"
                data-action="share/whatsapp/share"
                target="_blank"
                :class="$mq"
                class="offcanvas-bar__share-item offcanvas-bar__share-whatsapp external"
              >
                <img src="@/assets/images/top/wa.png" alt="" />
              </a>
              <a
                :href="$config.instagram.link"
                target="_blank"
                :class="$mq"
                class="offcanvas-bar__share-item offcanvas-bar__share-instagram external"
              >
                <img src="@/assets/images/top/ig.png" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div class="offcanvas-bar__besuch nav-item">
          Besuche uns
          <div class="nav-items-besuchen">
            <a
              :href="$config.facebook.link"
              target="_blank"
              class="icon-link external"
              :class="$mq"
            >
              <img src="@/assets/images/top/fb.png" alt="" />
            </a>
            <a
              :href="$config.instagram.link"
              target="_blank"
              class="icon-link external"
              :class="$mq"
            >
              <img src="@/assets/images/top/ig.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    props: {
      social: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        open: false,
      }
    },
    computed: {
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
    methods: {
      open_de_kinofinder() {
        window.open(
          `https://www.kino.de/film/${this.$config.kinoservice.slug}/`
        )
      },
      open_de_filmwecker() {
        window.open(
          `https://www.kino-zeit.de/alert/${this.$config.kinoservice.id}`
        )
      },
      openPremiere() {
        this.$router.push('/preview')
        this.open = !this.open
        var element = document.getElementById('nTrigger')
        element.classList.toggle('active')
      },
      openMenu() {
        this.open = !this.open
        EventBus.$emit('close-expandables')
        var element = document.getElementById('nTrigger')
        element.classList.toggle('active')
      },
      closeMenu(link) {
        this.open = !this.open
        var element = document.getElementById('nTrigger')
        element.classList.toggle('active')
        if (this.$route.meta.showModal) {
          this.$router.go(-1)
        }
        if (this.$router.currentRoute.path === '/mobile/artists') {
          this.$router.replace('/mobile').then(function () {
            window.location.href = `#mob-${link}`
          })
        }
      },
    },
  }
</script>

<style lang="scss">
  .mobile-header {
    $self: &;
    position: relative;

    &__wrapper {
      position: relative;
      width: 42px;
      background-color: white;
      height: 43px;
      position: fixed;
      left: 0;
      top: 0;
      color: black;
      z-index: 99999;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .social {
        margin-right: 10px;
        margin-bottom: 12px;

        div:nth-child(1) {
          height: 51px !important;
        }

        a {
          display: inline-block;
          margin-top: -3px;
          font-size: 32px;
        }

        svg path {
          fill: black;
        }
      }

      #{$self}__trigger-wrapper {
        display: flex;
        flex-direction: row;
        font-size: 30px;
        margin-top: 0px;
        font-weight: 900;
        width: 100%;
        color: black;
        justify-content: space-between;

        .mobile-social {
          text-align: right;
          margin-top: -3px;

          a {
            margin-right: 1rem;

            img {
              width: 16%;
              position: relative;
              top: -3px;
            }
          }

          .yt_blue {
            img {
              position: relative;
              top: -6px;
              width: 17%;
            }
          }
        }

        #{$self}__trigger {
          margin-top: 11px;
          cursor: pointer;
          width: 30px;
          height: 50px;
          padding: 0 10px;
          animation: outAll 0.8s forwards;

          i {
            background-color: black;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 4px;
          }

          i:nth-child(1) {
            -webkit-animation: outT 0.8s backwards;
            animation: outT 0.8s backwards;
            -webkit-animation-direction: reverse;
            animation-direction: reverse;
          }

          i:nth-child(2) {
            margin: 5px 0;
            -webkit-animation: outM 0.8s backwards;
            animation: outM 0.8s backwards;
            -webkit-animation-direction: reverse;
            animation-direction: reverse;
          }

          i:nth-child(3) {
            -webkit-animation: outBtm 0.8s backwards;
            animation: outBtm 0.8s backwards;
            -webkit-animation-direction: reverse;
            animation-direction: reverse;
          }

          &.active {
            animation: inAll 0.8s forwards;

            i:nth-child(1) {
              -webkit-animation: inT 0.8s forwards;
              animation: inT 0.8s forwards;
            }

            i:nth-child(2) {
              -webkit-animation: inM 0.8s forwards;
              animation: inM 0.8s forwards;
            }

            i:nth-child(3) {
              -webkit-animation: inBtm 0.8s forwards;
              animation: inBtm 0.8s forwards;
            }
          }
        }
      }
    }
  }

  .show-offcanvas {
    opacity: 1 !important;
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }

  .offcanvas-bar {
    $self: &;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 9999;
    width: 100%;
    transform: translateY(-100%);
    -webkit-transition: -webkit-transform 0.6s ease-out;
    transition: transform 0.6s ease-out;
    font-family: 'Open Sans';

    &__wrapper {
      margin-top: -80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 2rem;
      text-transform: uppercase;
      z-index: 999999;

      &.sm {
        font-size: 1.5rem;
      }

      .nav-item {
        position: relative;
        color: black;
        font-weight: 700;
        margin: 5px 0;
      }

      .nav-items-besuchen {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100px;
        margin: 10px auto 0 auto;
        .icon-link {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 50px;
          margin: 5px;

          &.sm {
            img {
              height: 40px;
            }
          }
        }
      }

      #{$self}__kinofinder {
        position: relative;
        text-align: left;
      }

      #{$self}__filmwecker {
        position: relative;
        text-align: left;
      }

      #{$self}__share {
        position: relative;
        text-align: left;
        width: 100%;
        text-align: center;

        #{$self}__share-btns {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
          margin-top: 10px;

          &.sm {
            margin-top: 0px;
          }

          .offcanvas-bar__share-btns-top,
          .offcanvas-bar__share-btns-bottom {
            display: flex;
            flex-direction: row;
            height: 100%;
          }

          #{$self}__share-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50px;
            margin: 5px;

            &.sm {
              img {
                height: 40px;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 600px) {
    .navTrigger {
      transform: translateX(50%) translateY(50%) scale(2);
    }
  }

  @keyframes inM {
    50% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(45deg);
      background-color: #d5c9b6;
    }
  }

  @keyframes outM {
    50% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(45deg);
    }
  }

  @keyframes inT {
    0% {
      transform: translateY(0px) rotate(0deg);
      background-color: #d5c9b6;
    }

    50% {
      transform: translateY(9px) rotate(0deg);
    }

    100% {
      transform: translateY(9px) rotate(135deg);
      background-color: #d5c9b6;
    }
  }

  @keyframes outT {
    0% {
      transform: translateY(0px) rotate(0deg);
    }

    50% {
      transform: translateY(9px) rotate(0deg);
    }

    100% {
      transform: translateY(9px) rotate(135deg);
    }
  }

  @keyframes inBtm {
    0% {
      transform: translateY(0px) rotate(0deg);
      background-color: #d5c9b6;
    }

    50% {
      transform: translateY(-9px) rotate(0deg);
    }

    100% {
      transform: translateY(-9px) rotate(135deg);
      background-color: #d5c9b6;
    }
  }

  @keyframes outBtm {
    0% {
      transform: translateY(0px) rotate(0deg);
    }

    50% {
      transform: translateY(-9px) rotate(0deg);
    }

    100% {
      transform: translateY(-9px) rotate(135deg);
    }
  }
</style>
